import Grid from '@mui/material/Grid';
import Image from 'next/image';
import { memo, FunctionComponent } from 'react';

import useBreakpoints from '../../Hooks/useBreakpoints';

const Evolution: FunctionComponent = () => {
  const { isDesktop } = useBreakpoints();
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs={12}
      spacing={4}
    >
      <Grid item className="barlow--size32 bold">
        Vamos dar o próximo passo rumo à evolução de sua empresa?
      </Grid>
      <Grid item className="open-sans--size18">
        Faça a transformação digital da comunicação de sua marca com os
        clientes, com nossas soluções tecnológicas via SMS.
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={{ xs: '12px', lg: '40px' }}
      >
        <Grid item>
          <Image
            src="/img/shift.webp"
            alt="seta"
            width={isDesktop ? 70 : 24}
            height={isDesktop ? 130 : 45}
          />
        </Grid>
        <Grid
          item
          className={`${isDesktop ? 'barlow--size48' : 'barlow--size24'} bold`}
        >
          Shift your evolution
          <span style={{ color: 'var(--primary-green)' }}>_</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Evolution);
